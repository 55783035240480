/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { useAuth0 } from "@auth0/auth0-react";
import { actions } from "../../store/modelActionCreators";
import { gridActions } from "../../store/gridActions";
import debounce from "lodash.debounce";
import { DateRange, Picker } from "@risk-first/ui-picker";

import {
  DependencyData,
  DependencyCheckClientAgent,
  DependencyCheckClient,
  RestorePendingDependencies,
} from "../../store/types";
import { RootState } from "../../store";

import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
  IRowNode,
  MenuItemDef
} from "ag-grid-community";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import * as formatter from "./valueFormatters";
import { DependencyStatus } from "../../store/types";
import { onCacheBlockLoaded, getLastWeekDate, getLast10YearDate } from "./gridUtils";
import { withTheme } from "@emotion/react";
import {
  SnackbarNotification,
  enqueueSnackbar as enqueueSnackbarAction,
} from "../../store/snackbarActions";
import jwt_decode from "jwt-decode";
import * as signalR from "@microsoft/signalr";
import axios from "axios";
import {
  ActionsContainer,
  ButtonsContainer,
  GridButton,
} from "./DependenciesGrid.style";
import moment from "moment";

const UPDATE_DEPENDENCIES_SCOPE = "update:dependencies";
const RESTORE_DEPENDENCIES_SCOPE = "restore:dependencies";
const RELEASE_DEPENDENCIES_SCOPE = "release:dependencies";
const cozonacApiUrl = process.env.REACT_APP_COZONAC_API_URL;
const innitialPickerDates = { from: getLastWeekDate(), to: new Date() }

type DecodedJwt = {
  permissions: Array<string>;
};

export const DependenciesGrid = withTheme(() => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<GridApi | null>(null);
  const [rowSelected, setRowSelected] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [datepickerDates, setDatepickersDate] = useState<DateRange | undefined>(innitialPickerDates);
  const [canRestore, setCanRestore] = useState(false);
  const [canRelease, setCanRelease] = useState(false);
  const [rowSelectedLDV, setRowSelectedLDV] = useState(false);
  const [rowSelectedADV, setRowSelectedADV] = useState(false);
  const [rowSelectedADVDep, setRowSelectedADVDep] = useState(false);
  const [rowSelectedDM, setRowSelectedDM] = useState(false);

  const dispatch = useDispatch();
  const auth0Context = useAuth0();
  const currentViewInfo = useSelector((state: RootState) => state.grid);
  const model = useSelector((state: RootState) => state.model);

  useEffect(() => {
    const getUserClaims = async () => {
      const { getAccessTokenSilently } = auth0Context;

      const token = await getAccessTokenSilently();
      const decoded = jwt_decode(token) as DecodedJwt;
      if (decoded.permissions.includes(UPDATE_DEPENDENCIES_SCOPE)) {
        setCanUpdate(true);
      }

      if (decoded.permissions.includes(RESTORE_DEPENDENCIES_SCOPE)) {
        setCanRestore(true);
      }

      if (decoded.permissions.includes(RELEASE_DEPENDENCIES_SCOPE)) {
        setCanRelease(true);
      }
    };

    getUserClaims();

    //setupSignalRConnection();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setupSignalRConnection = async () => {
    function onConnected(connection: signalR.HubConnection) {
      console.log("connection started");
      connection.send("broadcastMessage", "Connected");
    }

    function bindConnectionMessage(connection: signalR.HubConnection) {
      const messageCallback = function (message: string) {
        if (!message) return;
        // deal with the message
        console.log("message received:" + message);
      };
      // Create a function that the hub can call to broadcast messages.
      connection.on("SendMessageToClient", messageCallback);
    }

    const { getAccessTokenSilently } = auth0Context;

    try {
      const token = await getAccessTokenSilently();

      const resp = await axios.get(
        `${cozonacApiUrl}/api/hub/negotiate/dependencies`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const connection = new signalR.HubConnectionBuilder()
        .withUrl(resp.data.url, {
          accessTokenFactory: () => resp.data.accessToken,
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      bindConnectionMessage(connection);
      connection
        .start()
        .then(function () {
          onConnected(connection);
        })
        .catch(function (error) {
          console.error(error.message);
        });
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
      } else {
        console.log("Unexpected error", error);
      }
    }
  };

  const enqueueSnackbar = (notification: SnackbarNotification) =>
    dispatch(enqueueSnackbarAction(notification));

  const defaultColDef: ColDef = {
    minWidth: 50,
    enableValue: true,
    sortable: true,
    filter: true,
    resizable: true,
    floatingFilter: false,
    enableCellChangeFlash: true,
    suppressMenu: false,
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
      suppressAndOrCondition: true,
    },
  };

  const gridOptions: GridOptions = {
    alwaysShowHorizontalScroll: true,
    rowGroupPanelShow: "always",
    groupDisplayType: "groupRows",
    isRowSelectable: (node: IRowNode) => {
      return node.data
        ? node.data.status === DependencyStatus[0] ||
            node.data.status === DependencyStatus[2] ||
            node.data.status === DependencyStatus[3] ||
            node.data.status === 0
        : false;
    },
    rowMultiSelectWithClick: true,
    rowSelection: "multiple",
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressPivotMode: true,
          },
        },
        "filters",
      ],
    },
    rowModelType: "serverSide",
    suppressServerSideInfiniteScroll: false,
    blockLoadDebounceMillis: 5000,
    columnTypes: {
      groupColumn: {
        enableRowGroup: true,
      },
      textColumn: {
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: [
            "equals",
            "notEqual",
            "startsWith",
            "endsWith",
            "empty",
          ],
          suppressAndOrCondition: true,
        },
      },
      numberColumn: {
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: [
            "equals",
            "notEqual",
            "startsWith",
            "endsWith",
            "empty",
          ],
          suppressAndOrCondition: true,
        },
      },
      dateColumn: {
        filter: "agDateColumnFilter",
        valueFormatter: formatter.formatDate,
        filterParams: {
          comparator: formatter.dateComparator,
        },
      },
      dateTimeColumn: {
        filter: "agDateColumnFilter",
        valueFormatter: formatter.formatDateTime,
        filterParams: {
          comparator: formatter.dateComparator,
        },
      },
      statusColumn: {
        filter: "agSetColumnFilter",
        filterParams: {
          values: Object.keys(DependencyStatus).filter((k) => isNaN(Number(k))),
        },
      },
    },

    onFilterChanged: (event) => {
      const currentFilters = event.api?.getFilterModel() ?? {};

      const fromDateString = moment(datepickerDates?.from).format("YYYY-MM-DD HH:mm:ss");
      const toDateString = moment(datepickerDates?.to).format("YYYY-MM-DD HH:mm:ss");

      if(currentFilters["ValuationDate"])
      {
        if(currentFilters["ValuationDate"].type === 'equals'){
          if(currentFilters["ValuationDate"].dateFrom === fromDateString) return;
          setDatepickersDate( { from: new Date(currentFilters["ValuationDate"].dateFrom), to: new Date(currentFilters["ValuationDate"].dateFrom) });
        }
        else if(currentFilters["ValuationDate"].type === 'lessThan'){
          if(currentFilters["ValuationDate"].dateFrom === toDateString) return;
          setDatepickersDate({ from: getLast10YearDate(), to: new Date(currentFilters["ValuationDate"].dateFrom) });
        }
        else if(currentFilters["ValuationDate"].type === 'greaterThan'){
          if(currentFilters["ValuationDate"].dateFrom === fromDateString) return;
          setDatepickersDate( { from: new Date(currentFilters["ValuationDate"].dateFrom), to: new Date() });
        }
        else if(currentFilters["ValuationDate"].type === 'inRange') {
          if(currentFilters["ValuationDate"].dateFrom === fromDateString && currentFilters["ValuationDate"].dateTo === toDateString) return;
          setDatepickersDate( { from: new Date(currentFilters["ValuationDate"].dateFrom), to: new Date(currentFilters["ValuationDate"].dateTo) });
        }
        else {
          setDatepickersDate(undefined);
        }
      }
    }
  };

  const getContextMenuItems = (): (string | MenuItemDef)[] => {
    const result:(string | MenuItemDef)[] = ['copy','copyWithHeaders','separator','expandAll','contractAll','separator'];

    result.push({
      name:'Check Is Ready',
      action: () =>onCheckExistsClick(),
      icon: '<span class="ag-icon ag-icon-tick" unselectable="on" role="presentation"></span>',
      disabled : !rowSelected
    });

    result.push('separator');

    if(canUpdate && rowSelected){
      result.push(
        {
          name: 'Update pending dependencies',
          subMenu:[
            {
              name:'Abandon',
              action: () =>onAbandonClick(),
              icon: '<span class="ag-icon ag-icon-cross" unselectable="on" role="presentation"></span>',
              disabled : !rowSelected
            },
            {
              name:'Force Client',
              action: () =>onForceClientClick(),
              icon: '<span class="ag-icon ag-icon-eye" unselectable="on" role="presentation"></span>',
              disabled : !rowSelected
            },
            {
              name:'Force ClientAgent',
              action: () =>onForceClientAgentClick(),
              icon: '<span class="ag-icon ag-icon-eye" unselectable="on" role="presentation"></span>',
              disabled : !rowSelected
            },
          ],
          icon: '<span class="ag-icon ag-icon-group" unselectable="on" role="presentation"></span>',
          disabled : !canUpdate
        }
      );

      result.push('separator');
    }

    if(canRestore){
      if(rowSelectedADV) {
        result.push(
          {
            name:'Restore ADV',
            subMenu:[
              {
                name:'By Scheme',
                action: () =>onRestoreSchemePendingDependenciesClick('AssetDV'),
                icon: '<span class="ag-icon ag-icon-linked" unselectable="on" role="presentation"></span>',
                disabled : !rowSelectedADV
              },
              {
                name:'By Client',
                action: () =>onRestoreClientPendingDependenciesClick('AssetDV'),
                icon: '<span class="ag-icon ag-icon-linked" unselectable="on" role="presentation"></span>',
                disabled : !rowSelectedADV
              },
              {
                name:'By ClientAgent',
                action: () =>onRestoreClientAgentPendingDependenciesClick('AssetDV'),
                icon: '<span class="ag-icon ag-icon-linked" unselectable="on" role="presentation"></span>',
                disabled : !rowSelectedADV
              },
            ],
            icon: '<span class="ag-icon ag-icon-group" unselectable="on" role="presentation"></span>',
            disabled : !canRestore
          }
        );

        result.push('separator');
      }

      if(rowSelectedLDV) {
        result.push(
          {
            name:'Restore DV',
            subMenu:[
              {
                name:'By Scheme',
                action: () =>onRestoreSchemePendingDependenciesClick('LiabilityDV'),
                icon: '<span class="ag-icon ag-icon-linked" unselectable="on" role="presentation"></span>',
                disabled : !rowSelectedLDV
              },
              {
                name:'By Client',
                action: () =>onRestoreClientPendingDependenciesClick('LiabilityDV'),
                icon: '<span class="ag-icon ag-icon-linked" unselectable="on" role="presentation"></span>',
                disabled : !rowSelectedLDV
              },
              {
                name:'By ClientAgent',
                action: () =>onRestoreClientAgentPendingDependenciesClick('LiabilityDV'),
                icon: '<span class="ag-icon ag-icon-linked" unselectable="on" role="presentation"></span>',
                disabled : !rowSelectedLDV
              },
            ],
            icon: '<span class="ag-icon ag-icon-group" unselectable="on" role="presentation"></span>',
            disabled : !canRestore
          },
        );

        result.push('separator');
      }

      if(rowSelectedDM) {
        result.push(
          {
            name:'Restore DM',
            subMenu:[
              {
                name:'By Scheme',
                action: () =>onRestoreSchemePendingDependenciesClick('DailyMetric'),
                icon: '<span class="ag-icon ag-icon-linked" unselectable="on" role="presentation"></span>',
                disabled : !rowSelectedDM
              },
              {
                name:'AssetDV By Scheme',
                action: () =>onRestoreSchemePendingDependenciesClick('AssetDV'),
                icon: '<span class="ag-icon ag-icon-linked" unselectable="on" role="presentation"></span>',
                disabled : !rowSelectedADVDep
              },
              {
                name:'By Client',
                action: () =>onRestoreClientPendingDependenciesClick('DailyMetric'),
                icon: '<span class="ag-icon ag-icon-linked" unselectable="on" role="presentation"></span>',
                disabled : !rowSelectedDM
              },
              {
                name:'By ClientAgent',
                action: () =>onRestoreClientAgentPendingDependenciesClick('DailyMetric'),
                icon: '<span class="ag-icon ag-icon-linked" unselectable="on" role="presentation"></span>',
                disabled : !rowSelectedDM
              },
            ],
            icon: '<span class="ag-icon ag-icon-group" unselectable="on" role="presentation"></span>',
            disabled : !canRestore
          }
        );
        result.push('separator');
      }

      if(rowSelected) {
        result.push(
          {
            name:'Restore All By Environment',
            action: () =>onRestoreEnvPendingDependenciesClick('All'),
            icon: '<span class="ag-icon ag-icon-linked" unselectable="on" role="presentation"></span>',
            disabled : !rowSelected && !canRestore
          }
        );

        result.push('separator');
      }
    }

    result.push('export');

    return result;
  }

  const debounceSaveGridColumnState = debounce((columnState) => {
    dispatch(gridActions.saveGridColumnState(columnState));
  }, 100);

  const debounceSaveGridColumnGroupState = debounce((columnGroupState) => {
    dispatch(gridActions.saveGridColumnGroupState(columnGroupState));
  }, 100);

  const debounceSaveToLocalStorage = debounce(() => {
    dispatch(gridActions.saveStoreStateToLocalStorage());
  }, 100);

  const onGridReady = (event: GridReadyEvent): void => {
    setGridApi(event.api);
    setGridColumnApi(event.api);

    onCacheBlockLoaded(event, () => {
      const columnState = {
        state: currentViewInfo.columnState,
      };

      const columnGroupState = currentViewInfo.columnGroupState;
      if (columnState) {
        event.api.applyColumnState(columnState);
      } else {
        event.api.autoSizeAllColumns();
      }

      if (columnGroupState) {
        event.api.setColumnGroupState(columnGroupState);
      }

      const filterState = currentViewInfo.filterModel;

      if(filterState)
      {
        event.api.setFilterModel(filterState);
      }
    });

    const datasource = {
      getRows(params: IServerSideGetRowsParams) {
        dispatch(actions.fetchDependenciesDatasource(auth0Context, params));
      },
    };

    event.api?.setGridOption('serverSideDatasource', datasource)
  };

  const onSaveCurrentViewClick = (): void => {
    if (!gridColumnApi) return;
    const columnState = gridColumnApi.getColumnState();
    const columnGroupState = gridColumnApi.getColumnGroupState();

    debounceSaveGridColumnState(columnState);
    debounceSaveGridColumnGroupState(columnGroupState);
    debounceSaveToLocalStorage();
    enqueueSnackbar({
      message: "Successfully saved to local storage",
      key: new Date().getTime() + Math.random(),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    });
  };

  const onCheckExistsClick = async (): Promise<void> => {
    if (!gridApi) return;
    const selectedRows = gridApi.getSelectedRows().filter((r) => r.id != null);
    if (selectedRows.length > 0) {
      const payload = selectedRows
        .map((r: DependencyData) => r.partitionKey);

      await dispatch(actions.checkDependencyExists(auth0Context, payload));
      gridApi.deselectAll();
      gridApi.refreshServerSide({purge: true});
    }
  };

  const onAbandonClick = async (): Promise<void> => {
    if (!gridApi) return;
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      const payload = selectedRows.filter((r) => r.id != null);

      await dispatch(actions.abandonDependencies(auth0Context, payload));
      gridApi.deselectAll();
      gridApi.refreshServerSide({purge: true});
    }
  };

  const onRefreshClick = async (): Promise<void> => {
    if (!gridApi) return;
    gridApi.refreshServerSide({ purge: true });
  };

  const onRowSelected = async (): Promise<void> => {
    if (!gridApi) return;
    const selectedRows = gridApi.getSelectedRows().filter((r) => r.id != null);
    setRowSelected(selectedRows.length > 0);
    setRowSelectedLDV(selectedRows.some(e=>e.Type === 'Liability DV') && selectedRows.length > 0);
    setRowSelectedADV(selectedRows.some(e=>e.Type === 'Asset DV') && selectedRows.length > 0);
    setRowSelectedDM(selectedRows.some(e=>e.Type === 'Daily Metric') && selectedRows.length > 0);
    setRowSelectedADVDep(selectedRows.some(e=>e.Type === 'Daily Metric' && e.DependencyType === 'AssetDV') && selectedRows.length > 0);
  };

  const onForceClientClick = async (): Promise<void> => {
    if (!gridApi) return;
    const selectedRows = gridApi.getSelectedRows().filter((r) => r.id != null);
    if (selectedRows.length > 0) {
      const payload = selectedRows
        .map(
          (r: DependencyData) =>
            new DependencyCheckClient(r.partitionKey, r.metaData["Client"])
        );

      await dispatch(actions.forceClientDependency(auth0Context, payload));
      gridApi.deselectAll();
      gridApi.refreshServerSide({purge: true});
    }
  };

  const onForceClientAgentClick = async (): Promise<void> => {
    if (!gridApi) return;
    const selectedRows = gridApi.getSelectedRows().filter((r) => r.id != null);
    if (selectedRows.length > 0) {
      const payload = selectedRows
        .map(
          (r: DependencyData) =>
            new DependencyCheckClientAgent(
              r.partitionKey,
              r.metaData["ClientAgent"]
            )
        );

      await dispatch(actions.forceClientAgentDependency(auth0Context, payload));
      gridApi.deselectAll();
      gridApi.refreshServerSide({purge: true});
    }
  };

  const onRestoreSchemePendingDependenciesClick = async (paramType: unknown): Promise<void> =>{
    if (!gridApi) return;

    const selectedRows = gridApi.getSelectedRows().filter((r) => r.id != null);
    if (selectedRows.length > 0) {
      const payload = selectedRows
        .map(
          (r: DependencyData) =>
            new RestorePendingDependencies(
              r.metaData["AppName"],
              null,
              null,
              r.metaData["SchemeId"],
              paramType,
              r.metaData["ValuationDate"]
            )
        );

      await dispatch(actions.restorePendingDependencies(auth0Context, payload));
      gridApi.deselectAll();
    }
  }

  const onRestoreClientPendingDependenciesClick = async (paramType: unknown): Promise<void> => {
    if (!gridApi) return;

    const selectedRows = gridApi.getSelectedRows().filter((r) => r.id != null);
    if (selectedRows.length > 0) {
      const payload = selectedRows
        .map(
          (r: DependencyData) =>
            new RestorePendingDependencies(
              r.metaData["AppName"],
              null,
              r.metaData["Client"],
              null,
              paramType,
              r.metaData["ValuationDate"]
            )
        );

      await dispatch(actions.restorePendingDependencies(auth0Context, payload));
      gridApi.deselectAll();
    }
  }

  const onRestoreClientAgentPendingDependenciesClick = async (paramType: unknown): Promise<void> => {
    if (!gridApi) return;

    const selectedRows = gridApi.getSelectedRows().filter((r) => r.id != null);
    if (selectedRows.length > 0) {
      const payload = selectedRows
        .map(
          (r: DependencyData) =>
            new RestorePendingDependencies(
              r.metaData["AppName"],
              r.metaData["ClientAgent"],
              null,
              null,
              paramType,
              r.metaData["ValuationDate"]
            )
        );

      await dispatch(actions.restorePendingDependencies(auth0Context, payload));
      gridApi.deselectAll();
    }
  }

  const onRestoreEnvPendingDependenciesClick = async (paramType: unknown): Promise<void> => {
    if (!gridApi) return;

    if(!rowSelected)
    {
        alert('Please select at least one `Pending` dependency.');
        return;
    }

    const selectedRows = gridApi.getSelectedRows().filter((r) => r.id != null);
    if (selectedRows.length > 0) {
      const payload = selectedRows
        .map(
          (r: DependencyData) =>
            new RestorePendingDependencies(
              r.metaData["AppName"],
              null,
              null,
              null,
              paramType,
              r.metaData["ValuationDate"]
            )
        );

      await dispatch(actions.restorePendingDependencies(auth0Context, payload));
      gridApi.deselectAll();
    }
  };

  const onRestoreAllPendingDependenciesClick = async (): Promise<void> => {
    const payload = new Array<RestorePendingDependencies>();
    payload.push(new RestorePendingDependencies('PFaroeDemo',null,null,null,'All',null));
    payload.push(new RestorePendingDependencies('PFaroePunt',null,null,null,'All',null));
    payload.push(new RestorePendingDependencies('PFaroeChan',null,null,null,'All',null));
    payload.push(new RestorePendingDependencies('PFaroe',null,null,null,'All',null));
    payload.push(new RestorePendingDependencies('Skyval',null,null,null,'All',null));

    await dispatch(actions.restorePendingDependencies(auth0Context, payload));
  };

  const onDatepickerDatesChange = (value: DateRange | undefined) => {
    const currentFilters = gridApi?.getFilterModel() ?? {};
    const fromDateMoment = moment(value?.from);
    const toDateMoment = moment(value?.to);

    const fromDateString = fromDateMoment.format("YYYY-MM-DD HH:mm:ss");
    const toDateString = toDateMoment.format("YYYY-MM-DD HH:mm:ss");

    

    if (fromDateString === toDateString) {
        if(currentFilters["ValuationDate"] &&
           currentFilters["ValuationDate"].dateFrom === fromDateString &&
           currentFilters["ValuationDate"].type === "equals"){

            return;
        }
        else {
          currentFilters["ValuationDate"] = {
            dateFrom: fromDateString,
            dateTo: null,
            filterType: "date",
            type: "equals",
          };
        }
    }
    else {
      if (currentFilters["ValuationDate"] &&
        currentFilters["ValuationDate"].dateFrom === fromDateString &&
        currentFilters["ValuationDate"].toFrom === toDateString) {

        return;
      }
      else {
        currentFilters["ValuationDate"] = {
          dateFrom: fromDateString,
          dateTo: toDateString,
          filterType: "date",
          type: "inRange",
        };
      }
    }

    gridApi?.setFilterModel(currentFilters);
    gridApi?.onFilterChanged();
  };

  return (
    <>
      <ActionsContainer>
        <ButtonsContainer>
          <GridButton size="small" variant="primary" onClick={onRefreshClick}>
            Refresh
          </GridButton>
          <GridButton size="small" variant="primary" onClick={onSaveCurrentViewClick}>
            Save Current View
          </GridButton>
          {canRelease && canRestore && (
            <>
              <GridButton size="small" variant="primary" onClick={onRestoreAllPendingDependenciesClick}>
                Restore
              </GridButton>
            </>
          )}
        </ButtonsContainer>
        <Picker
          format="dd/mm/yyyy"
          initialResolution="day"
          mode="range"
          navigationOrdering="ascending"
          value={datepickerDates}
          onChange={onDatepickerDatesChange}
        />
      </ActionsContainer>

      <div className="ag-theme-alpine" style={{ height: 920, width: "100%" }}>
        {/* @ts-ignore */}
        <AgGridReact
          columnDefs={model.columnDefinitions}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onRowSelected={onRowSelected}
          animateRows={true}
          getContextMenuItems={getContextMenuItems}
        />
      </div>
    </>
  );
});